import 'viewerjs/dist/viewer.min.js';
import 'jquery-viewer/dist/jquery-viewer.min.js';
import 'select2/dist/js/select2.min.js';
import AOS from 'aos';
import Flickity from 'flickity';
import 'flickity/dist/flickity.pkgd.js';

// sticky header variable
var shrinkHeader = 10;

export default {
  init() {
    // JavaScript to be fired on all pages

    // Flickity.defaults.dragThreshold = 3;
  },
  finalize() {
    AOS.init();
    // JavaScript to be fired on all pages, after page specific JS is fired

    let mapsSectionWidth = checkMapWidth();

    if ( mapsSectionWidth == true) {
      $('#map-address-wrp').addClass('flickity-now');
      mapSectionSlider();
    } else {
      mapSectionSliderDestroy();
    }

    let wpcf7Element = document.querySelector('#motor-calculator-section .calculator-contact-form-wrp .wpcf7');

    if (wpcf7Element) {
      wpcf7Element.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();
        $('#motor-calculator-section').addClass('invalid-form');
      }, false );

      wpcf7Element.addEventListener( 'wpcf7mailsent', function(event) {
        event.preventDefault();
        $('#motor-calculator-section').removeClass('invalid-form');
      }, false );
    }

    // Add Apllications for Motor Calculations
    let wpcf7AccordionForm = document.querySelector('.accordion-form-section .wpcf7');

    if (wpcf7AccordionForm) {
      wpcf7AccordionForm.addEventListener( 'wpcf7invalid', function(event) {
        event.preventDefault();

        $('.accordion-form-section .accordion-item-wrp').each(function() {
          $(this).find('.faq-title').addClass('collapsed');
          $(this).find('.faq-content').removeClass('show');
        });

        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-title').removeClass('collapsed');
        $('.accordion-form-section .accordion-item-wrp').first().find('.faq-content').addClass('show');
      }, false );
    }
    // Add Apllications for Motor Calculations

    // Calculations Page Proceed Button Click
    $('#calculator-proceed-button').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section').removeClass('step-1');
      $('#motor-calculator-section').addClass('step-2');
      $('#motor-selection').css({'display':'none'});
      let selectedMotor = $('input[name="motor-select"]:checked').val();
      let selectedMotorSeperated = selectedMotor.split('-');
      let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

      $('.motor-details-wrp .form-motor-item-wrp').each(function() {
        let imageWrp = $(this).find('.img-wrp');
        if ($(this).is(`#motor-${selectedMotorId}`)) {
          $(this).fadeIn(500);
          $('.change-btn-wrp').appendTo(imageWrp);
          $('.change-btn-wrp').removeClass('d-none');
        }
      });

      $('.calculator-inner-wrp').css({'display':'none'});
      $('.calculator-form-wrp').fadeIn(500);
    });

    // Calculations Page Goto Edit Button CLick
    $('#goto-edit-calculator').click(function(e){
      e.preventDefault();

      $('#motor-calculator-section').addClass('step-1');
      $('#motor-calculator-section').removeClass('step-2');
      $('.calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display', 'none');
      $('.calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'none');

      $('.motor-details-wrp .form-motor-item-wrp').each(function() {
        $(this).css('display', 'none');
      });

      $('#motor-selection').fadeIn(500);

      $('.calculator-inner-wrp').fadeIn(500);
      $('.calculator-form-wrp').css({'display':'none'})
    });

    // Calculations Page CF7 On Mail Sent Function
    if (document.querySelector( '.calculator-contact-form-wrp .wpcf7' )) {
      var wpcf7Elm = document.querySelector( '.calculator-contact-form-wrp .wpcf7');

      wpcf7Elm.addEventListener( 'wpcf7submit', function() {
        $('.calculator-page .wpcf7-form-control-wrap').children('.wpcf7-not-valid-tip').css('display','block');
        $('.calculator-contact-form-wrp').find('.wpcf7-response-output').css('display', 'block');
      }, false );

      wpcf7Elm.addEventListener( 'wpcf7mailsent', function() {
        let selectedMotor = $('input[name="motor-select"]:checked').val();
        let selectedMotorSeperated = selectedMotor.split('-');
        let selectedMotorId = parseFloat(selectedMotorSeperated[1]);

        $('.motor-datasheets-wrp .motor-datasheet-item').each(function() {
          if ($(this).is(`#motor-${selectedMotorId}`)) {
            // $(this).removeClass('d-none');
            $(this).fadeIn(500);
          }
        });

        $('#motor-calculator-section .calculator-form-wrp').css({'display':'none'})
        $('.motor-details-wrp .form-motor-item-wrp').each(function() {
          // $(this).addClass('d-none');
          $(this).css({'display':'none'});
        });
      }, false );
    }

    // Insights & Products Load More
    $(document).ready(function () {
      $('.products-wrp .product-item').slice(0, 12).show();
        if ($('.products-wrp .product-item').length > 12) {
          $('#loadMoreProducts').show();
        } else {
          $('#loadMoreProducts').hide();
        }
      $('#loadMoreProducts').on('click', function (e) {
        e.preventDefault();
        $('.products-wrp .product-item:hidden').slice(0, 2).slideDown();
        if ($('.products-wrp .product-item:hidden').length == 0) {
          $('#loadMoreProducts').text('No More to view').fadeOut('slow');
          $('#loadMoreProducts').addClass('load-more-end');
        }
      });
    });

    $(document).ready(function () {
      $('.insights-item-v1').slice(0, 4).show();
      $('.insights-item-v2').slice(0, 4).show();
      if ($('.insights-item-v1').length > 4 || $('.insights-item-v2').length > 4) {
        $('#loadMoreInsights').show();
      } else {
        $('#loadMoreInsights').hide();
        $('#loadMoreInsights').text('').fadeOut('slow');
        $('#loadMoreInsights').addClass('load-more-end');
      }
      $('#loadMoreInsights').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-v1:hidden').slice(0, 1).slideDown();
        $('.insights-item-v2:hidden').slice(0, 1).slideDown();
        if ($('.insights-item-v1:hidden').length == 0 || $('.insights-item-v2:hidden').length == 0) {
          $('#loadMoreInsights').text('No More to view').fadeOut('slow');
          $('#loadMoreInsights').addClass('load-more-end');
        }
      });
    });
    // Insights & Products Load More

    // Insights & News Mobile Load More
    $(document).ready(function () {
      $('.insights-item-mobile').slice(0, 4).show();
      if ($('.insights-item-mobile:hidden').length != 0) {
        $('#loadMoreInsightsMobile').show();
      } else {
        $('#loadMoreInsightsMobile').hide();
        $('#loadMoreInsightsMobile').text('').fadeOut('slow');
        $('#loadMoreInsightsMobile').addClass('load-more-end');
      }
      $('#loadMoreInsightsMobile').on('click', function (e) {
        e.preventDefault();
        $('.insights-item-mobile:hidden').slice(0, 2).slideDown();
        if ($('.insights-item-mobile:hidden').length == 0) {
          $('#loadMoreInsightsMobile').text('No More to view').fadeOut('slow');
          $('#loadMoreInsightsMobile').addClass('load-more-end');
        }
      });
    });
    // Insights & News Mobile Load More

    //map-section popup+
    $('.contact-map-section #map-address-wrp .title-wrp-outer').each(function () {
      $(this).click(function() {
        $('.map-details').slideUp();
        $(this).next('.map-details').slideDown();
      });
    });

    $('.contact-map-section .flickity-button').click(function () {
      $('.show-address .map-details').slideUp();
      $('.item-wrp').each(function () {
        $(this).removeClass('show-address');
      });
    });
    //map-section popup

    // Product Listing Button Action
    $('.product-listing-view-datasheet').click(function (e) {
      e.preventDefault();
      $(this).slideUp();
      $(this).siblings('.product-listing-view-datasheet-content').slideDown();
    });

    var wpcf7ElmListing = document.querySelectorAll( '.products-details-section .motor-content-wrp .wpcf7' );

    for (let i = 0; i <wpcf7ElmListing.length; i++) {
      wpcf7ElmListing[i].addEventListener( 'wpcf7mailsent', function() {
        window.open($(this).parent().siblings('.datasheet-link').attr('href'));
      }, false );
    }
    // Product Listing Button Action

    // toggle class on hamburger icon click
    $('.mobile-burger-icon').click(function () {
      $('body, .mobile-burger-icon').toggleClass('show-menu');
      $('.mobile-menu .sub-menu-wrap').removeClass('show-menu');
    });

    // mobile sub menu navigation
    // level 1
    $('.mobile-menu .nav > li.menu-item-has-children').each(function () {
      $(this).append('<span class="sub-menu-click"></span>');
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> .sub-menu-click').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // level 2
    $('.mobile-menu .nav > li.menu-item-has-children > .sub-menu-wrap li.menu-item-has-children').each(function () {
      $(this).find('> .sub-menu-wrap > .sub-menu').prepend('<div class="return-link">' + $(this).find('> a').text() + '</div>');
      $(this).find('> a').click(function (e) {
          e.preventDefault();
          $(this).siblings('.sub-menu-wrap').toggleClass('show-menu');
        });
    });

    // return link click
    $('.return-link').each(function () {
      $(this).click(function () {
        $(this).parent().parent('.sub-menu-wrap').removeClass('show-menu');
      });
    });

    // set header height to sticky overlay
    $('.sticky-overlay').outerHeight($('.header').outerHeight());


    // add class to product details navigation carousel when have a one item
    if($('.products-details-section .product-carousel-nav .sub-image-wrp-outer').length <= 1) {
      $('.products-details-section .product-carousel-nav').addClass('d-none');
    }

    // home section scroll on ID click
    if(window.location.hash) {
      $('html, body').animate({ scrollTop: $(window.location.hash).offset().top - 100}, 1000);
    }

    $('body').on('click', '.nav .menu-item a', function (e) {
      if (/#/.test(this.href)) { //check if anchor href has '#' value
        e.preventDefault();

        if(window.location.pathname !== '/') {
          window.location = '/'+$(this).attr('href');
        }

        $('body, .mobile-burger-icon').removeClass('show-menu');
        $('html, body').animate({ scrollTop: $($(this).attr('href')).offset().top - 100}, 1000);
      }
    });
    // home section scroll on ID click


    buttonArrow();
    deleteCookie('PREF');
    formScripts();
    jqueryViewer('.gallery-images-viewer-v2');
    jqueryViewer('.gallery-images-viewer-v1');
    fullWidthVideoPlayerFile();
    videoPopupPlayerFile();
    delayHomeBannerVideo();
    productSectionSlider();
    checkSectionsForGaps();
    editorContentAdditions();
    gallerySectionSlider();
    cardSectionGridSliderNew();
    cardSectionSliderNew();
    logoSectionSlider();
    logoSectionGridSlider();
    testimonialSlider();
    stickyProductFilter();
    productDetailSlider();
    circleAnimation();
    cookieConsent();
    capabilitiesSlider();
    flickitySliderArrowPosition();
    capabilitiesCardHeightDefiner();

    $('.product-section .product-items-wrp, .logo-slider-section .logo-slider-view, .cards-section-new .cards-wrp, .testimonials-section .testimonials-slider, .products-details-section .product-carousel-nav, .products-details-section .product-nav').flickity('resize');
  },
};

$(window).on('resize', function () {
  let mapSectionWidthResize = checkMapWidth();

  if ( mapSectionWidthResize == true) {
    $('#map-address-wrp').addClass('flickity-now');
    mapSectionSlider();
  }

  $('.sticky-overlay').outerHeight($('.header').outerHeight());

  // resizing width calculate and do the resizing ops - start
  const resizeDelta = Math.abs($(window).width() - $(window).data('previousWidth'));

  if (resizeDelta > 10) {
    $('.product-section .product-items-wrp, .logo-slider-section .logo-slider-view, .cards-section-new .cards-wrp, .testimonials-section .testimonials-slider, .products-details-section .product-carousel-nav, .products-details-section .product-nav').flickity('resize');
    $('.flickity-viewport').css('height', '400');
  }

  $(window).data('previousWidth', $(window).width());
  // resizing width calculate and do the resizing ops - end

  flickitySliderArrowPosition();
  capabilitiesCardHeightDefiner();
});

$(window).on('load', function () {
  $('.product-section .product-items-wrp, .logo-slider-section .logo-slider-view, .cards-section-new .cards-wrp, .testimonials-section .testimonials-slider, .products-details-section .product-carousel-nav, .products-details-section .product-nav').flickity('resize');
});

//map slider
function mapSectionSlider() {
  var cellAlignValue = '';

  if (matchMedia('screen and (max-width: 768px)').matches) {
    cellAlignValue = 'center';
  } else {
    cellAlignValue = 'left';
  }

  $('.contact-map-section .main-carousel.flickity-now'
  ).flickity({
    cellAlign: cellAlignValue,
    pageDots: false,
    prevNextButtons: true,
    wrapAround: true,
    initialIndex: 2,
  });
}

function mapSectionSliderDestroy() {
  $('.contact-map-section .main-carousel.flickity-now').flickity('destroy');
}

// sticky menu
$(window).scroll(function () {
  var scroll = getCurrentScroll();
  if (scroll >= shrinkHeader) {
    $('body, .header-container').addClass('sticky-header');
  } else {
    $('body, .header-container').removeClass('sticky-header');
  }

  $('.sticky-overlay').outerHeight($('.header').outerHeight());
});

function getCurrentScroll() {
  return window.pageYOffset || document.documentElement.scrollTop;
}
// sticky menu

function formScripts() {
  if ($('.form-dropdown')) {
    $('.form-dropdown').each(function() {
      var dropDownPlaceHolder =  $('.dropdown-placeholder').html();
      $('.dropdown-placeholder').css('display', 'none');

      $(this).select2({
        dropdownParent: $(this).parent().parent(),
        placeholder: dropDownPlaceHolder,
      });
    });
  }

  $('form').each(function() {
    $(this).find('.button-wrp-outter').append($(this).find('.wpcf7-response-output'));
  });
}

function productSectionSlider() {
  $('.product-section').each(function() {
    $(this).find('.product-items-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'left',
      watchCSS: true,
    });

    // arrow appending moved to the 'flickitySliderArrowPosition()' function
  });
}

// Capabilities Card Hight Definer - Start
function capabilitiesCardHeightDefiner() {
  $('.capabilities-section .capabilities-wrp .desktop-content').each(function() {
    let $maxHeight = 0;

    $(this).find('.card-wrp .card-inner-wrp').height('auto');
    $maxHeight = 0;

    $(this).find('.card-wrp .card-inner-wrp').each(function() {
      if($(this).height() > $maxHeight){
        $maxHeight = $(this).height();
      }
    })

    $(this).each(function() {
      $(this).find('.card-wrp .card-inner-wrp').height($maxHeight)
    });
  });
}
// Capabilities Card Hight Definer - End


// Implementation of Jquery Viewer
function jqueryViewer(ulId) {
  var $galleryImages = $(ulId);

  $galleryImages.each(function() {
    $galleryImages.viewer({
      tooltip: 0,
      inline: false,
      toolbar: {
        zoomIn: {
          show: 1,
          size: 'large',
        },
        zoomOut: {
          show: 1,
          size: 'large',
        },
        oneToOne: 0,
        reset: 0,
        prev: {
          show: 1,
          size: 'large',
        },
        play: {
          show: 0,
          size: 'large',
        },
        next: {
          show: 1,
          size: 'large',
        },
        rotateLeft: 0,
        rotateRight: 0,
        flipHorizontal: 0,
        flipVertical: 0,
      },
      title: 0,
      viewed: function () {
        $galleryImages.viewer('zoomTo', 1);
      },
    });
  });
}
// Implementation of Jquery Viewer


// Full Width Video Player
function fullWidthVideoPlayerFile() {
  $('.play-video-file-wrp .playvideo.video-file').click(function() {
    $('.play-video-file-wrp').each(function() {
      let videoEl = $(this).children('video').get(0);
      if (videoEl.currentTime > 0 && !videoEl.paused && !videoEl.ended && videoEl.readyState > 2) {
        videoEl.pause();
      }
      $(this).children('video').css('opacity', 0);
      $(this).children('.video-thumb').css({
          'opacity' : '1',
          'z-index' : '1',
      });
    });

    $(this).siblings('.iframe-video').css('opacity','1');
    $(this).siblings('.iframe-video').get(0).play();
    $(this).css({
      'opacity' : '0',
      'z-index' : '-1',
    });
  });
}

function fullWidthVideoPlayerLink() {
  for (let i = 1; i <= $('.playvideo.video-url').length ; i++) {
    let idName = 'play-video-' + i;
    let newIdName = 'video-iframe-' + i;
    $('.playvideo.video-url')[i-1].setAttribute('id', idName);
    $('.iframe-video.video-url')[i-1].setAttribute('id', newIdName);
    $('#'+idName).click(function(){

      if ($('#'+newIdName).hasClass('video-element')) {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName).get(0).play();
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      } else {
        $('#'+ newIdName).css('opacity','1');
        $('#'+ newIdName)[0].src += '&autoplay=1';
        $('#' + idName).css({
          'opacity' : '0',
          'z-index' : '-1',
        });
      }
    });
  }
}
// Full Width Video Player


// Popup Video Player for Two Column Section
function videoPopupPlayerFile() {
  var videoSrc;
  var videoControls;
  $('.video-iframe-thumbnail.video-file').click(function () {
    videoSrc = $(this).data('src');
    videoControls = $(this).data('controls');
    console.log(videoSrc);
  });

  $('#myModal-video-file.video-file').on('shown.bs.modal', function () {
    console.log(videoSrc);
    $('#myModal-video-file #video').attr(
      'src',
      videoSrc + '?autoplay=1&mute=1&amp&modestbranding=1&amp&'+ videoControls+'&showinfo=0;'
    );
  });

  $('#myModal-video-file').on('hide.bs.modal', function () {
    $('#myModal-video-file #video').attr('src', '');
  });
}

function videoPopupPlayerLink() {
  var videoSrc;
  var videoControls;
  $('.video-iframe-thumbnail.video-url').click(function () {
    videoSrc = $(this).data('src');
    videoControls = $(this).data('controls');
  });

  $('#myModal-video-url.video-url').on('shown.bs.modal', function () {
    $('#myModal-video-url #video').attr(
      'src',
      videoSrc + '?autoplay=1&mute=1&amp&modestbranding=1&amp&'+ videoControls+'&showinfo=0;'
    );
  });

  $('#myModal-video-url').on('hide.bs.modal', function () {
    $('#myModal-video-url #video').attr('src', '');
  });
}
// Popup Video Player for Two Column Section


// Delay Home Banner Video
function delayHomeBannerVideo() {
  setTimeout(function() {
    $('#home-banner-section .home-banner-slider .banner-img.has-video').addClass('d-none');
  }, 3000);
}
// Delay Home Banner Video

//sticky product navigation - product listing
function stickyProductFilter() {
  var header = $('.stick-to-top');

  if (header.length) {
    var sticky = header.offset().top;

    let elementHeight = header.outerHeight();
    var nextDiv = header.next();

    $(window).scroll(function() {
      if ($(window).scrollTop() > sticky) {
        header.css({
          position: 'sticky',
          top: '0',
          width: '100%',
          zIndex: '10',
        });
        nextDiv.css({
          paddingTop: elementHeight + 'px',
          position: 'static',
          zIndex: '9',
        });
      } else {
        header.css({
          position: '',
          top: '',
          width: '',
          zIndex: '',
        });
        nextDiv.css({
          paddingTop: '0',
          position: 'static',
          zIndex: '0',
        });
      }
    });
  }
}

// Header Scroll up Option

function checkSectionsForGaps() {
  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1.no-caption, .fcp-section.video-section.v1, .fcp-section.cta-section').each(function () {
    if($(this).next().hasClass('bg-enabled')) {
      $(this).addClass('bg-next');
    }
  });

  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1.no-caption, .fcp-section.video-section.v1').each(function () {
    if($(this).next().hasClass('cta-section')) {
      $(this).addClass('cta-next');
    }
  });

  $('.fcp-section.bg-enabled, .fcp-section.with-image, .fcp-section.image-section.v1.no-caption, .fcp-section.video-section.v1, .fcp-section.cta-section').each(function () {
    if($(this).next().hasClass('image-section') && $(this).next().hasClass('v1') && $(this).next().hasClass('no-caption')) {
      $(this).addClass('img-next');
    }
  });
}

function editorContentAdditions() {
  var target = '.btn',
      invert = ':not(' + target + ')',
      wrap = '<div class="button-wrapper">',
      breakpoints = $('.editor-content-wrp > *'+invert);

  $('.editor-content-wrp').each(function( ) {
    $(this).find('.btn').unwrap();
  });

  breakpoints.each(function(){
    $(this).nextUntil(invert).wrapAll(wrap);
  });

  breakpoints.first().prevUntil(invert).wrapAll(wrap);
}

function checkMapWidth() {
  let addressWrapperWidth = $('#map-address-wrp').width();
  let mapWrapperWidth = $('.map-wrp').width();

  if (mapWrapperWidth < addressWrapperWidth) {
    return true;
  } else {
    return false;
  }
}

function cookieConsent() {
  let cookiePreferences = getCookie('ineticAviationPopupCookieAllowed').split(',');
  if (cookiePreferences[0] == 'necessary%2Cperformance%2Cfunctional%2Ctargetting') {
    cookiePreferences = cookiePreferences[0].split('%2C');
  }
  // On-Load Cookie Popup and Fixed Icon
  if( getCookie('ineticAviationPopupCookie') != 'submited'){
    if ($('#cookie-consent-section').length >= 1) {
      setTimeout(function() {
        $('#cookie-consent-section').slideDown('slow');
      }, 500);
    }
  } else {
    if (cookiePreferences.includes('functional')) {
      fullWidthVideoPlayerLink();
      videoPopupPlayerLink();
      $('.video-iframe-thumbnail.video-url').removeClass('disable-popup');
    } else {
      $('.video-iframe-thumbnail.video-url').addClass('disable-popup');
    }

    if ($('#fixed-cookie-logo').length >= 1) {
      setTimeout(function() {
        $('#fixed-cookie-logo').slideDown('slow');
      }, 500);
    }
  }
  // On-Load Cookie Popup and Fixed Icon

  // Accept All Button Click
  $('#accept-all-button').on('click', function () {
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });
    setCookie('ineticAviationPopupCookie', 'submited', 100);
    setCookie('ineticAviationPopupCookieAllowed', ['necessary', 'performance', 'functional', 'targetting'], 100);
    fullWidthVideoPlayerLink();
    videoPopupPlayerLink();
    $('.video-iframe-thumbnail.video-url').removeClass('disable-popup');
  });
  // Accept All Button Click

  // Accept Necessary Button Click
  $('#accept-necessary-button').on('click', function () {
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });
    setCookie('ineticAviationPopupCookie', 'submited', 100);
    setCookie('ineticAviationPopupCookieAllowed', ['necessary'], 100);
    deleteGoogleCookies();
    $('.video-iframe-thumbnail.video-url').addClass('disable-popup');
  });
  // Accept Necessary Button Click

  // Accept Customize Accept Button Click
  $('#accept-customize-button').on('click', function () {
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });

    $('#cookiePopupModal').modal('show');
  });

  $('#confirm-preferences').on('click', function () {
    setCookie('ineticAviationPopupCookie', 'submited', 100);
    let cookieOptions = [];
    if ($('#necessary-cookies-toggle').is(':checked')) {
      cookieOptions.push('necessary');
    }
    if ($('#performance-cookies-toggle').is(':checked')) {
      cookieOptions.push('performance');
    } else {
      deleteGoogleCookies();
    }
    if ($('#functional-cookies-toggle').is(':checked')) {
      cookieOptions.push('functional');
      fullWidthVideoPlayerLink();
      videoPopupPlayerLink();
      $('.video-iframe-thumbnail.video-url').removeClass('disable-popup');
    } else {
      $('.video-iframe-thumbnail.video-url').addClass('disable-popup');
    }
    if ($('#targetting-cookies-toggle').is(':checked')) {
      cookieOptions.push('targetting');
    }

    $('#cookiePopupModal').modal('hide');

    setCookie('ineticAviationPopupCookieAllowed', cookieOptions, 100);
    $('#cookie-consent-section').slideUp('slow', function() {
      $('#fixed-cookie-logo').slideDown('slow');
    });
  });
  // Accept Customize Accept Button Click

  $('#cookiePopupModalClose').on('click', function () {
    $('#fixed-cookie-logo').slideUp('slow', function() {
      $('#cookie-consent-section').slideDown('slow');
    });

    $('#cookiePopupModal').modal('close');
  });


  // Cookie Fixed Icon Click
  $('#fixed-cookie-logo').on('click', function () {
    let cookiePreferences = getCookie('ineticAviationPopupCookieAllowed').split(',');
    if (cookiePreferences[0] == 'necessary%2Cperformance%2Cfunctional%2Ctargetting') {
      cookiePreferences = cookiePreferences[0].split('%2C');
    }

    if (cookiePreferences.includes('performance')) {
      $('#performance-cookies-toggle').prop('checked', true);
    } else {
      $('#performance-cookies-toggle').prop('checked', false);
    }

    if (cookiePreferences.includes('functional')) {
      $('#functional-cookies-toggle').prop('checked', true);
    } else {
      $('#functional-cookies-toggle').prop('checked', false);
    }

    if (cookiePreferences.includes('targetting')) {
      $('#targetting-cookies-toggle').prop('checked', true);
    } else {
      $('#targetting-cookies-toggle').prop('checked', false);
    }

    $('#fixed-cookie-logo').slideUp('slow', function() {
      $('#cookie-consent-section').slideDown('slow');
    });
  });
  // Cookie Fixed Icon Click
}

// Cookie Create, Delete and Get Values

function deleteCookie(cname) {
  document.cookie = cname+'=; Max-Age=-99999999;';
}

function getCookie(cname) {
  var name = cname + '=';
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return '';
}

function setCookie(cname, cvalue, exdays) {
  var d = new Date();
  d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
  var expires = 'expires=' + d.toUTCString();
  document.cookie = cname + '=' + cvalue + ';' + expires + ';path=/';
}

// Delete Google Analytic Cookies
function deleteGoogleCookies() {
  deleteCookie('_ga');
  deleteCookie('_ga_EXFNGAKKTZ');
}
// Delete Google Analytic Cookies

// Cookie Create, Delete and Get Values

function cardSectionGridSliderNew() {
  $('.cards-section-new.grid').each(function() {

  $(this).find('.cards-wrp').flickity({
    pageDots: true,
    prevNextButtons: true,
    wrapAround: true,
    watchCSS: true,
  });

  // arrow appending moved to the 'flickitySliderArrowPosition()' function
});
}

function cardSectionSliderNew() {
  $('.cards-section-new.slider').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.cards-wrp').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    // arrow appending moved to the 'flickitySliderArrowPosition()' function
  });
}

function gallerySectionSlider() {
  $('.gallery-section.v1').each(function() {
    var cellAlignValue = '';

    if (matchMedia('screen and (max-width: 768px)').matches) {
      cellAlignValue = 'center';
    } else {
      cellAlignValue = 'left';
    }

    $(this).find('.gallery-wrp-slider').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: cellAlignValue,
    });

    // arrow appending moved to the 'flickitySliderArrowPosition()' function
  });
}

function logoSectionGridSlider() {
  $('.logo-slider-section.logo-grid').each(function() {

    $(this).find('.logo-slider-view').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
      watchCSS: true,
      autoPlay: true,
    });

    // arrow appending moved to the 'flickitySliderArrowPosition()' function
  });
}

function logoSectionSlider() {
  $('.logo-slider-section.logo-slider').each(function() {

      $(this).find('.logo-slider-view').flickity({
        // contain: 1,
        lazyLoad: true,
        prevNextButtons: true,
        pageDots: true,
        autoPlay: true,
        watchCSS: true,
        wrapAround: true,
        cellAlign: 'center',
      });

      // arrow appending moved to the 'flickitySliderArrowPosition()' function
  });
}


function testimonialSlider() {
  $('.testimonials-section').each(function() {

    let speedDuration = 5000;

    $(this).find('.testimonials-slider').flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
      cellAlign: 'center',
      autoPlay: speedDuration,
    });

    // arrow appending moved to the 'flickitySliderArrowPosition()' function
  });
}

function buttonArrow() {
  $('.btn:not(.generated)').each(function() {
    $(this).html('<span>' + $(this).html() + '</span>');
    $(this).append('<span class="arrow-icon"></span>');
    $(this).addClass('generated');
    $(this).css({ display: 'flex' });
  });
}

function productDetailSlider() {
  $('.products-details-section .sliders-wrp').each(function(){
    var productNavClassName = '.product-nav';
    var mainCarousel = $(this).find(productNavClassName);
    var asNavCarousel =  $(this).find('.product-carousel-nav');

    // 1st carousel, main
    $(mainCarousel).flickity({
      pageDots: true,
      prevNextButtons: true,
      wrapAround: true,
    });

    // 2nd carousel, navigation
    $(asNavCarousel).flickity({
      asNavFor: productNavClassName,
      contain: true,
      pageDots: false,
      prevNextButtons: false,
    });
  });
}

function circleAnimation() {
  $('.circle-rings-animation').each(function() {
      $(this).append('<div class="animation-item"></div>');
      $(this).append('<div class="animation-item"></div>');
      $(this).append('<div class="animation-item"></div>');
  });
}


function capabilitiesSlider() {
  $('.capabilities-section.v2').each(function() {
    if ($('.capabilities-section.v2 .capabilities-cards-wrp').children().length > 2) {
      // Play with this value to change the speed
      let flickity = null;
      const slideshowEl = document.querySelector('.capabilities-section.v2 .capabilities-cards-wrp');

      flickity = new Flickity(slideshowEl, {
        prevNextButtons: true,
        pageDots: true,
        draggable: true,
        wrapAround: true,
        cellAlign:'left',
      });

      $(this).find('.flickity-button').appendTo($(this).find('.pagination-control'));
      $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-dots'));
      flickity.x = 0;
    }
  });
}

function flickitySliderArrowPosition() {
  // Settime out function to delay function and trgger after flickity has initialized
  setTimeout(function() {
    $('.product-section').each(function() {
      // Check if flickity is initialzed
      if ($('.product-section .flickity-enabled').length) {
        // clone flickity default controllers to the custom wrapper 'controller-wrp'
        $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-dots'));
        $(this).find('.flickity-button').appendTo($(this).find('.pagination-control'));

        // hide default flickity controllers
        // $(this).find('.flickity-enabled .flickity-page-dots').hide();
        // $(this).find('.flickity-enabled .flickity-button').hide();
      }
    });

    $('.logo-slider-section').each(function() {
      // Check if flickity is initialzed
      if ($('.logo-slider-section .flickity-enabled').length) {
        // clone flickity default controllers to the custom wrapper 'controller-wrp'

        if (matchMedia('screen and (min-width: 575px)').matches) {
          $(this).find('.flickity-button').appendTo($(this).find('.pagination-control-desk'));
        } else {
          $(this).find('.flickity-button').appendTo($(this).find('.pagination-control'));
          $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-dots'));
        }
      }
    });

    $('.cards-section-new').each(function() {
      // Check if flickity is initialzed
      if ($('.cards-section-new .flickity-enabled').length) {
        // clone flickity default controllers to the custom wrapper 'controller-wrp'
        $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-dots'));
        $(this).find('.flickity-button').appendTo($(this).find('.pagination-control'));
      }
    });

    $('.gallery-section.v1').each(function() {
      // Check if flickity is initialzed
      if ($('.gallery-section.v1 .flickity-enabled').length) {
        // clone flickity default controllers to the custom wrapper 'controller-wrp'
        $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-dots'));
        $(this).find('.flickity-button').appendTo($(this).find('.pagination-control'));
      }
    });

    $('.testimonials-section ').each(function() {
      // Check if flickity is initialzed
      if ($('.testimonials-section .flickity-enabled').length) {
        // clone flickity default controllers to the custom wrapper 'controller-wrp'
        $(this).find('.flickity-page-dots').appendTo($(this).find('.pagination-dots'));

        if (matchMedia('screen and (min-width: 768px)').matches) {
          $(this).find('.flickity-button').appendTo($(this).find('.pagination-control-desk'));
        } else {
          $(this).find('.flickity-button').appendTo($(this).find('.pagination-control'));
        }
      }
    });

  },400);
}