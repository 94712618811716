import 'flickity/dist/flickity.pkgd.js';

export default {
  init() {
    // JavaScript to be fired on the home page
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  },
};

$(window).on('resize', function () {
  $('.gallery-wrp, .home-banner-slider').flickity('resize');
});

$(window).on('load', function () {
  $('.gallery-wrp, .home-banner-slider',).flickity('resize');
});
